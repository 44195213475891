import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import Link from "next/link";
import clsx from "clsx";

export function BoardsMenu({ boards }) {
  const { query } = useRouter();
  const boardRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(60);

  useEffect(() => {
    if (boardRef.current) setHeight(boardRef.current?.offsetHeight);
  }, [boardRef.current]);

  const { board = "All" } = query;

  return (
    <div className="relative flex py-3 overflow-auto bg-ecn-light-red">
      <div
        style={{
          height,
        }}
      />
      <div
        className="absolute flex items-center gap-3 text-ecn-light px-2.5 min-h-max"
        ref={boardRef}
      >
        {boards.map(({ name }) => (
          <Link key={name} href={{ query: { ...query, board: name } }} passHref>
            <a>
              <button
                className={clsx(
                  "rounded-lg px-3 py-2 text-sm hover:bg-ecn-gray/70",
                  name === board && "bg-ecn-gray text-ecn-dark font-bold",
                  "whitespace-nowrap"
                )}
              >
                {name}
              </button>
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
}

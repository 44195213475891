import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";

export function TimeLine() {
  const { query } = useRouter();
  const { time = "1D" } = query;

  return (
    <section className="flex items-center gap-3 text-sm">
      <h5 className="px-3 py-2 font-extrabold text-white rounded-md bg-ecn-dark">
        Time
      </h5>
      {["1H", "4H", "1D", "1W", "1M"].map((moment) => (
        <Link
          key={moment}
          href={{
            query: { ...query, time: moment },
          }}
          passHref
        >
          <a>
            <button
              className={clsx(
                "rounded-md px-3 py-2.5 hover:bg-ecn-gray/70",
                moment === time && "bg-ecn-gray text-ecn-dark font-bold"
              )}
            >
              {moment}
            </button>
          </a>
        </Link>
      ))}
    </section>
  );
}

import { css } from "@emotion/css";
import { clsx, Skeleton, SkeletonProps } from "@mantine/core";
import { themeColor } from ".";

export function Skeletal({ children, className, ...props }: SkeletonProps) {
  return (
    <Skeleton
      {...props}
      className={clsx(
        "z-auto",
        className,
        css({
          "&::before": {
            background: themeColor["ecn-mc-dim-red"],
          },
          "&::after": {
            background: themeColor["ecn-mc-light-red"],
          },
        })
      )}
    >
      {!props.visible && children}
    </Skeleton>
  );
}
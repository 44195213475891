import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { BuyAndSellTabIcon, BuyTabIcon, SellTabIcon } from ".";

export function OrderBookMenu() {
  const { query } = useRouter();
  const { orders = "All" } = query;

  return (
    <div className="relative flex text-ecn-light items-center gap-3 overflow-auto bg-ecn-light-red py-3 px-2.5">
      {[
        {
          name: "Order Book",
          value: "All",
          icon: BuyAndSellTabIcon,
        },
        { name: "Buy Order", value: "Buy", icon: BuyTabIcon },
        {
          name: "Sell Order",
          value: "Sell",
          icon: SellTabIcon,
        },
      ].map(({ value, icon: Tab }) => (
        <Link
          key={value}
          href={{ query: { ...query, orders: value } }}
          passHref
        >
          <a>
            <button
              className={clsx(
                "rounded-lg px-3 py-2 text-sm hover:bg-ecn-gray/70",
                value === orders
                  ? "bg-ecn-gray text-ecn-dark font-bold"
                  : "opacity-30"
              )}
            >
              <Tab />
            </button>
          </a>
        </Link>
      ))}
    </div>
  );
}

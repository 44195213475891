import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { themeColor, MarketPriceDisplay } from ".";
import { css } from "@emotion/css";
import { SecurityMonitoringDataType } from "@/sample/queries";
import { useMemo } from "react";

export function SecuritiesMenu({ securities }) {
  const { query } = useRouter();
  const { board = "All", security = "SMAZ" } = query;

  const securitiesByBoard: Array<SecurityMonitoringDataType> = useMemo(() => {
    const securityList =
      securities
        ?.sort((a, b) => a.code.localeCompare(b.code))
        ?.reduce((acc, data) => {
          const { board_name } = data;
          acc["All"] = (acc["All"] ?? []).concat([data]);

          if (data.security_type === "Dawa") {
            acc["Deliverable"] = (acc["Deliverable"] ?? []).concat([data]);
            return acc;
          }

          acc[board_name] = (acc[board_name] ?? []).concat([data]);
          return acc;
        }, {}) ?? {};

    return securityList[board as string];
  }, [board, securities]);

  return (
    <section className="grid overflow-auto w-full grid-cols-[repeat(4,_minmax(max-content,_100%))] justify-around content-start bg-ecn-light-red py-3 px-2.5 h-full">
      {securitiesByBoard?.map((item) => {
        return (
          <Link
            key={item.code}
            href={{
              query: {
                ...query,
                orders: "All",
                security: item.code,
                view: "Trading",
                time: "1D",
              },
            }}
            passHref
          >
            <a
              className={clsx(
                "contents",
                css({
                  "& > *": {
                    backgroundColor:
                      item.code === security
                        ? themeColor["ecn-mc-gray"]
                        : "transparent",
                  },
                  "&:hover > *": {
                    backgroundColor: themeColor["ecn-mc-bg-red"],
                    borderBottom: `1px solid ${themeColor["ecn-mc-fresh-red"]}`,
                    borderRadius: 0
                  },
                })
              )}
            >
              <MarketPriceDisplay {...item} />
            </a>
          </Link>
        );
      })}
    </section>
  );
}
